export default function Icone2() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M17.5595 6.776V6.00518H17.9845V6.776C19.3285 6.78588 20.1487 7.53694 20.1487 8.70306H19.0221C18.9826 7.99153 18.6565 7.66541 18.0042 7.66541H17.9845V9.75059C18.8541 9.97788 19.4668 10.2645 19.8127 10.6104C20.1586 10.9562 20.3266 11.4009 20.3266 11.9445C20.3266 13.2687 19.4767 14.0889 17.9845 14.168V14.9388H17.5595V14.168C16.0475 14.1087 15.168 13.2687 15.168 11.9346V11.8555H16.2946V11.8753C16.2946 12.7746 16.7195 13.2489 17.5595 13.2786V10.8871C15.9685 10.4819 15.3162 9.82965 15.3162 8.71294C15.3162 7.55671 16.2649 6.78588 17.5595 6.776ZM16.4428 8.63388C16.4428 9.14776 16.7986 9.464 17.5595 9.64188V7.66541H17.5299C16.8579 7.66541 16.4428 7.96188 16.4428 8.63388ZM17.9845 10.976V13.2786C18.7553 13.2292 19.2 12.8536 19.2 12.1125C19.2 11.5096 18.8344 11.1835 17.9845 10.976Z"
                fill="black"
            />
            <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="black" />
            <path
                d="M17.6533 16.0536C20.9818 16.0536 23.6801 13.3553 23.6801 10.0268C23.6801 6.69829 20.9818 4 17.6533 4C14.3248 4 11.6265 6.69829 11.6265 10.0268C11.6265 13.3553 14.3248 16.0536 17.6533 16.0536Z"
                stroke="black"
                strokeWidth="1.2106"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.0309 21.3217L25.6534 18.6483C26.3051 18.271 27.1343 18.4627 27.556 19.0861C27.9959 19.7378 27.8284 20.6216 27.1807 21.0675L20.4175 25.7162L13.2144 23.6582L11.2976 24.669"
                stroke="black"
                strokeWidth="1.2106"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.8447 21.4247L19.1282 22.6494C19.905 22.8714 20.7161 22.4214 20.9381 21.6446C21.16 20.8678 20.7101 20.0567 19.9332 19.8348L15.9766 18.7049C15.9766 18.7049 11.5417 14.8874 8.16416 19.0237"
                stroke="black"
                strokeWidth="1.2106"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.8319 25.647L7.43587 17.7327L5 19.0857L9.39604 27L11.8319 25.647Z"
                stroke="black"
                strokeWidth="1.2106"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
